import _ from 'lodash';
import React, { Component, Fragment, Suspense, lazy } from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { AiFillDelete } from 'react-icons/ai';
import Select from 'react-dropdown-select';
import Loading from 'root/components/Loading/Loading';
import SVGElements from 'root/components/SVGElements';
import APIClient from '../../../utils/APIClient';
import { trans_massageHideAddBank } from 'root/utils/transaction-util';
import commonUtil from 'root/utils/common-util';
import Button from '../../Share/button/button';
import { gu_handleUploadReceiptButtonOnClick, gu_filterCommonKey } from 'root/utils/general-util';
import { wd_handleReceiptImageChange, wd_handleClearReceipt } from 'root/utils/withdrawal-util';
import { ou_filterMasterSwitchAuthMethods, ou_constructContactVerificationMethods, ou_otpErrorHandler } from '@utils/otp-util';
import BankIcon from '../../../components/Molecules/bank-icon';
import { MODULE_TYPE as MT, POPUP_TYPE, OTP_VERIFICATION_TYPE as OVT, CONTACT_VERIFICATION_SINGLE_LAYER } from '@constants';
import { useAuthSettingsSetup } from '@components/molecules/auth-settings/auth-settings';
import AddCryptoAddressPopup from '@components/organisms/add-crypto-address-popup/add-crypto-address-popup';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import { popupAction } from '@redux/action/popup.action';
import notification from '@utils/notification';
import { SetupSixDigitPinNotice } from '@components/organisms/set-up-pin-popup/set-up-pin-popup';
import { portalAction, authSettingsAction } from '@redux/action';

// import styles from './bank-details.module.scss';
const AuthOtpTabs = lazy(() => import('@components/molecules/auth-otp-tabs/auth-otp-tabs'));
const AuthOtpTab = lazy(() => import('@components/molecules/auth-otp-tabs/auth-otp-tabs').then((module) => ({ default: module.AuthOtpTab })));

class BankDetail extends Component {
    bankDetailCategories = [
        {
            id: 0,
            name: this.props.t('settings:settings.profiletab.bank', 'Bank Details'),
            bankDetailType: 'bank',
            code: 'B',
        },
        {
            id: 1,
            name: this.props.t('transaction:transaction.deposit.crypto.crypto', 'Crypto') + ' ' + this.props.t('transaction:transaction.address', 'Address'),
            bankDetailType: 'crypto',
            code: 'CT',
        },
    ];
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            selectedFilterType: [
                {
                    id: 0,
                    name: this.props.t('settings:settings.profiletab.bank', 'Bank Details'),
                    bankDetailType: 'bank',
                },
            ],
            bankDetailType: 'bank',
            memberBankList: [],
            showLoading: false,
            bankList: [],
            disabledButton: true,
            selectedUnOwned: [],
            accountNumber: '',
            bankBranch: '',
            bankName: '',
            bankNameErrMsg: [],
            statusMsg: [],
            showDelete: false,
            cryptoAddress: [],
            addressOption: [],
            selectedCurrency: [],
            networkOption: [],
            selectedNetwork: [],
            addAddressErrMsg: '',
            cryptoAddressDisable: true,
            address: '',
            otpCode: '',
            disableOtpBtn: true,
            submittedOTP: false,
            countdownTimer: 0,
            ewalletList: [],
            ewalletBank: [],
            unOwnedEwalletBank: [],
            hideAddBankAccount: false,
            errors: [],
            receiptImg: [],
            receiptImgFile: null,
            isInvalidUploadFileType: false,
            showBankName: false,
            compSettings: {},
            otpErrMsg: false,
            preferContact: null,
            attemptCount: 0,
            repopulatedAuthMethod: [],
            selectedContact: [],
        };
        this.MAX_ITEMS_LENGTH = 30;
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
    }

    componentDidMount() {
        this.initCompSettings().then(() => {
            this.loadEwallet().then(() => {
                this.loadBankAccounts();
            });
        });

        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/bankdetail') {
            this.props.history.push('/myaccount/bankdetails');
        } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/bankdetails') {
            this.props.history.push('/myaccount/myprofile');
            this.props.history.push('/bankdetail');
        }
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    initCompSettings = () => {
        return new Promise((resolve) => {
            const { user } = this.props;
            const { currency } = user.account;
            this.setState({}, () => {
                // Step 1: Standard way to get from portal settings
                let tempSettings = commonUtil.getSettingFromPortalSettings({
                    vm: this,
                    settingName: 'withdrawalSettings',
                });

                window.SPL_Content.getBankNameJson(this.props);

                let optionCode = this.bankDetailCategories && this.bankDetailCategories[0] && this.bankDetailCategories[0].code;
                let hideAddBankAccount = trans_massageHideAddBank(this, 'withdraw', optionCode);

                let reqBankStatementByCurrencies = false;
                if (tempSettings.reqBankStatementByCurrencies) {
                    reqBankStatementByCurrencies = tempSettings.reqBankStatementByCurrencies.indexOf(currency) !== -1;
                }

                // Step 3: Initial all into compSettings
                let compSettings = {
                    seperateTngToEwallet: tempSettings && tempSettings.seperateTngToEwallet,
                    reqBankStatementByCurrencies,
                };

                this.setState(
                    {
                        hideAddBankAccount,
                        compSettings,
                    },
                    () => {
                        resolve();
                    }
                );
            });
        });
    };

    loadEwallet() {
        return new Promise((resolve) => {
            const { compSettings } = this.state;
            if (compSettings && compSettings.seperateTngToEwallet) {
                window.SPL_Transaction.getWithdrawalBankList().then((data) => {
                    if (data && data.R && data.R.R.length > 0) {
                        let param = {
                            id: 3,
                            name: this.props.t('transaction:transaction.deposit.cashdeposit.qrpay', 'Ewallet'),
                            bankDetailType: 'ewallet',
                        };
                        this.bankDetailCategories.push(param);

                        this.setState({ ewalletEnabled: true, ewalletList: data.R.R });
                        resolve();
                    } else {
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });
    }

    onTabClick = (index) => {
        let bankDetailType = '';

        if (index.length > 0) {
            for (let i = 0; i < index.length; i++) {
                bankDetailType = index[i].bankDetailType;
                this.selectedFilterType = [this.bankDetailCategories[index[i].id]];

                let optionCode = index[i].code;
                let hideAddBankAccount = trans_massageHideAddBank(this, 'withdraw', optionCode);

                this.setState({
                    bankDetailType: bankDetailType,
                    histories: [],
                    selectedFilterType: [this.bankDetailCategories[index[i].id]],
                    activeTab: index[i].id,
                    hideAddBankAccount,
                });
            }
        }

        if (index[0].bankDetailType === 'crypto') {
            this.toggleLoading(true);
            this.getMemberCryptoAddress();
            this.loadCryptoWithdraw();
            this.handleSetPreferContact();
        }
    };

    getMemberCryptoAddress = () => {
        window.SPL_Transaction.getMemberCryptoAddress().then((data) => {
            if (data && data.orginalAddressList) {
                this.setState({
                    cryptoAddress: data.orginalAddressList,
                });
            }
        });
    };

    loadBankAccounts = () => {
        this.toggleLoading(true);
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank()
            .then((data) => {
                const { ewalletList, ewalletEnabled } = this.state;
                let { ewalletBank, unOwnedEwalletBank } = this.state;
                for (let i = 0; i < data.memberBankList.length; i++) {
                    const memberbank = data.memberBankList[i];
                    //only tng is image
                    if (memberbank.code === 'TNG') {
                        memberbank.isSvgImage = true;
                    }
                }

                //filter tng in bank details
                if (ewalletEnabled && ewalletList && ewalletList.length > 0) {
                    for (let i = 0; i < ewalletList.length; i++) {
                        ewalletBank = data.memberBankList.filter((data) => data.code === ewalletList[i].code);
                        unOwnedEwalletBank = data.unOwnedBankList.filter((data) => data.code === ewalletList[i].code);
                        data.memberBankList = data.memberBankList.filter((data) => data.code !== ewalletList[i].code);
                        data.unOwnedBankList = data.unOwnedBankList.filter((data) => data.code !== ewalletList[i].code);
                    }
                }

                if (this.isMount) {
                    this.setState({
                        memberBankList: data.memberBankList.reverse(),
                        unOwnedBankList: data.unOwnedBankList,
                        ewalletBank,
                        unOwnedEwalletBank,
                    });
                }
                this.toggleLoading(false);
            })
            .catch((err) => {
                this.toggleLoading(false);
            });
    };
    handleSave(id, text) {
        let items = this.state.items;
        let index = _.findIndex(items, { id });
        items[index].text = text;
        this.setState({
            items,
        });
    }

    handleChecked(id, checked) {
        let items = this.state.items;
        let index = _.findIndex(items, { id });
        items[index].checked = checked;
        this.setState({
            items,
        });
    }

    calculateID(items) {
        if (items.length === 0) {
            return 0;
        }
        return _.maxBy(items, 'id').id + 1;
    }

    handleClick() {
        if (this.state.value.length === 0) {
            return;
        }
        let items = this.state.items;
        items.push({ id: this.calculateID(items), text: this.state.value });
        this.setState({
            items,
            value: '',
        });
    }
    handleChange(event) {
        this.setState({
            value: event.target.value,
        });
    }

    handleDelete = (id) => {
        this.toggleLoading(true);
        window.SPL_Member.updateBankAccStatus(id)
            .then((data) => {
                if (data.status === 'S') {
                    this.loadBankAccounts();
                } else {
                    if (this.isMount) {
                        this.setState({ errMsg: data.errMsg });
                    }
                    this.toggleLoading(false);
                }
            })
            .catch((err) => {
                this.toggleLoading(false);
            });
    };

    toggleLoading = (toggle) => {
        this.setState({ showLoading: toggle });
    };

    closeBankModal = () => {
        this.setState({ isOpenBankModal: false });
    };

    doNothing = () => {
        return 0;
    };

    valdiate = () => {
        const { accountNumber, accountName, selectedUnOwned, bankName } = this.state;
        if (
            accountNumber === '' ||
            accountName === '' ||
            (selectedUnOwned.length > 0 && selectedUnOwned[0].bankId === '0' && bankName === '') ||
            selectedUnOwned.length === 0
        ) {
            this.setState({ disabledButton: true });
        } else {
            this.setState({ disabledButton: false });
        }
    };
    componentDidUpdate() {}

    isNumber = (event) => {
        return /^\d*$/.test(event.target.value);
    };

    handleBankNameChange = (event) => {
        this.setState({ bankName: event.target.value }, () => {
            this.valdiate();
            this.validateBankName();
        });
    };

    handleBankBranchChange = (event) => {
        this.setState({ bankBranch: event.target.value }, () => {
            this.valdiate();
        });
    };

    handleAccountNumberChange = (event) => {
        if (this.isNumber(event)) {
            this.setState({ accountNumber: event.target.value }, () => {
                this.valdiate();
            });
        }
    };

    handleBankChange = (value) => {
        let showBankName = false;
        if (value.length > 0) {
            if (value[0].bankId === '0' || value[0].bankId === 0 || value[0].bankId === 'o') {
                showBankName = true;
            } else {
                showBankName = false;
            }
        }

        this.setState(
            {
                selectedUnOwned: value,
                showBankName: showBankName,
                bankName: '',
            },
            () => {
                this.valdiate();
            }
        );
    };

    validateBankName = () => {
        window.SPL_Transaction.validateBankName(this.state.bankName).then((errMsg) => {
            if (this.isMount) {
                this.setState({ bankNameErrMsg: errMsg });
            }
        });
    };

    handleSubmit = () => {
        const { authSettingsReducer, popupReducer, dispatch } = this.props;
        let _currentModule = null;
        if (this.state.bankDetailType === 'bank') {
            _currentModule = MT.BANK_DETAIL_BANK;
        } else if (this.state.bankDetailType === 'ewallet') {
            _currentModule = MT.BANK_DETAIL_EWALLET;
        }
        const _currentModuleAuthSettings = authSettingsReducer?.processedData?.[_currentModule];
        const _config = {
            gaEnabled: _currentModuleAuthSettings?.gaEnabled,
            sixPinEnabled: _currentModuleAuthSettings?.sixPinEnabled,
        };

        // if enable Pin, but haven't got enter pin number for GA yet, then will trigger pin popup
        if (_currentModuleAuthSettings?.sixPinEnabled && !popupReducer?.enteredPin) {
            dispatch(popupAction.setItemConfig(POPUP_TYPE.PIN_ENTRY, _config));
            dispatch(popupAction.togglePopUp(POPUP_TYPE.PIN_ENTRY, true));
            dispatch(popupAction.setSubmitSuccessCb(this.submitAddBankDetail.bind(this)));
        } else {
            this.submitAddBankDetail();
        }
    };

    submitAddBankDetail = () => {
        const { selectedUnOwned, accountNumber, bankBranch, bankName, receiptImgFile } = this.state;
        const { t, popupReducer, dispatch } = this.props;
        // accountName, accountNumber, bankBranch, bankId, bankName
        this.setState({ disabledButton: true });
        dispatch(portalAction.setIsSubmitting(true));
        window.SPL_Member.addBankAcc(
            this.props.accountName,
            accountNumber,
            bankBranch,
            selectedUnOwned[0].bankId,
            bankName,
            receiptImgFile,
            popupReducer?.enteredPin,
            OVT.SIX_PIN
        )
            .then((data) => {
                if (data.status === 'S') {
                    if (this.isMount) {
                        this.setState({
                            selectedUnOwned: [],
                            accountNumber: '',
                            bankBranch: '',
                            bankName: '',
                            disabledButton: false,
                        });
                    }
                    this.handleClearReceipt();
                    this.loadBankAccounts(selectedUnOwned[0].bankId);
                    this.doNothing();
                    dispatch(popupAction.togglePopUp(POPUP_TYPE.PIN_ENTRY, false));
                    dispatch(popupAction.setEnteredPin(''));
                } else {
                    if (this.isMount) {
                        if (data.errorCode === 2717) {
                            dispatch(authSettingsAction.setSixPinLocked()); // this will trigger affiliate-hook setModuleSixPinLocked
                        }
                        if (data.detail === 'Bank account already in use') {
                            data.detail = t('transaction:transaction.duplicateBankAccount');
                            notification.showNotification('error', t('transaction:transaction.duplicateBankAccount'));
                        } else {
                            notification.showNotification('error', t(data?.errMsg));
                        }
                        this.setState({ statusMsg: [data.detail], disabledButton: false });
                    }
                }
                dispatch(portalAction.setIsSubmitting(false));
            })
            .catch(() => {
                dispatch(portalAction.setIsSubmitting(false));
            });
    };

    showDeleteBtn = (id) => {
        this.setState({
            showDelete: id,
        });
    };

    customNoDataRenderer = ({ props, state, methods }) => (
        <Translation>{(t) => <div className='select-noData'> {t('reports:reports.noDataAvailable', 'No Data Available')}</div>}</Translation>
    );

    loadCryptoWithdraw = () => {
        window.SPL_Transaction.getCryptWithdrawBank().then((data) => {
            if (data) {
                let addressData = data.coinAndSupportNetwork,
                    addressOption = [];

                if (addressData) {
                    for (let i = 0; i < Object.keys(addressData).length; i++) {
                        addressOption.push({
                            displayName: Object.keys(addressData)[i],
                        });
                    }

                    this.setState({
                        addressData: addressData,
                        addressOption: addressOption,
                    });
                }
            }

            this.toggleLoading(false);
        });
    };

    handleCryptoCurrencyChange = (value) => {
        this.setState({ selectedCurrency: value, selectedNetwork: [] }, () => {
            this.filterAddressData();
            this.checkAddressBtnDisable();
            this.checkOtpBtnDisable();
        });
    };

    filterAddressData = () => {
        const { addressData, selectedCurrency } = this.state;
        let networkFilter = '',
            networkOption = [];

        if (selectedCurrency && selectedCurrency.length > 0) {
            networkFilter = addressData[selectedCurrency[0].displayName];

            for (let i = 0; i < networkFilter.length; i++) {
                networkOption.push({ displayName: networkFilter[i] });
            }
        }

        this.setState({ networkOption: networkOption });
    };

    handleCryptoNetworkChange = (value) => {
        this.setState({ selectedNetwork: value }, () => {
            this.checkAddressBtnDisable();
            this.checkOtpBtnDisable();
        });
    };

    handleAddressChange = (e) => {
        if (e.target.value.match(/^([^`~!@#$%^&*()\-_=+[{\]}\\\|;:',<.>\/? ]*)$/)) {
            this.setState({ address: e.target.value }, () => {
                this.checkAddressBtnDisable();
                this.checkOtpBtnDisable();
            });
        }
    };

    sendOtpCode = async () => {
        const { preferContact, address } = this.state;

        if (!this.state.disableOtpBtn) {
            const _otpApiParam = {
                contactType: 'CRYPTO',
                cryptoAddress: address,
                verificationMethod: preferContact?.toUpperCase(),
            };
            const splParams = {
                otpOptions: { otpType: MT.CRYPTO_ADDRESS, isPublic: false },
                data: _otpApiParam,
            };

            try {
                // Make the API call to request OTP
                const res = await window.SPL_Member.postRequestOTP(splParams);

                // Update state on successful OTP request
                this.setState(
                    {
                        submittedOTP: true,
                        countdownTimer: 60,
                        addAddressErrMsg: '',
                        otpErrMsg: false,
                    },
                    () => {
                        // Start the countdown timer
                        this.timerCount();
                    }
                );
            } catch (err) {
                const _errObj = ou_otpErrorHandler(err);

                let errMsg = '';

                errMsg = _errObj?.key ? `otp:otp.request.message.${_errObj?.key}` : _errObj?.message;

                this.setState({ addAddressErrMsg: errMsg, otpErrMsg: true, otpCode: '', attemptCount: _errObj?.maxAttempt });
            }
        }
    };

    timerCount() {
        let vm = this,
            secondCount = 0;

        if (vm.state.countdownTimer > 0) {
            setTimeout(() => {
                secondCount = vm.state.countdownTimer - 1;
                vm.setState({ countdownTimer: secondCount });
                this.timerCount();
            }, 1000);
        }
    }

    handleOtpChange = (e) => {
        this.setState({ otpCode: e.target.value }, () => {
            if (this.state.otpCode.length === 6) {
                this.validateOtp();
            }
        });
    };

    validateOtp = () => {
        this.toggleLoading(true);
        window.SPL_Member.validateOtp(null, this.state.otpCode, this.state.address)
            .then((result) => {
                if (result) {
                    this.checkAddressBtnDisable();
                } else {
                    if (!this.state.otpErrMsg) {
                        this.setState({
                            addAddressErrMsg: 'global:global.verification.codeIncorrect',
                            cryptoAddressDisable: true,
                        });
                    }
                }

                this.toggleLoading(false);
            })
            .catch(() => {
                if (!this.state.otpErrMsg) {
                    this.setState({
                        addAddressErrMsg: 'global:global.verification.generalError',
                    });
                }
                this.toggleLoading(false);
            });
    };

    checkOtpBtnDisable = () => {
        const { selectedCurrency, selectedNetwork, address } = this.state;

        if (selectedCurrency && selectedCurrency.length > 0 && selectedNetwork && selectedNetwork.length > 0 && address) {
            this.setState({ disableOtpBtn: false });
        } else {
            this.setState({ disableOtpBtn: true });
        }
    };

    checkAddressBtnDisable = () => {
        const { selectedCurrency, selectedNetwork, address, otpCode } = this.state;
        const { addCryptoAddressRequireOTP } = this.props.portal.settings;

        if (
            selectedCurrency &&
            selectedCurrency.length > 0 &&
            selectedNetwork &&
            selectedNetwork.length > 0 &&
            address &&
            ((addCryptoAddressRequireOTP && otpCode) || (!addCryptoAddressRequireOTP && !otpCode))
        ) {
            this.setState({ cryptoAddressDisable: false });
        } else {
            this.setState({ cryptoAddressDisable: true });
        }
    };

    handleCryptoAddressSubmit = () => {
        const { selectedCurrency, selectedNetwork, address } = this.state;
        const params = {
            address: address,
            cryptoCoin: selectedCurrency[0].displayName,
            cryptoNetwork: selectedNetwork[0].displayName,
        };

        const onSuccess = () => {
            this.toggleLoading(true);
            this.setState(
                {
                    selectedNetwork: [],
                    selectedCurrency: [],
                    address: '',
                    otpCode: '',
                    countdownTimer: 0,
                    submittedOTP: false,
                    addAddressErrMsg: '',
                    cryptoAddressDisable: true,
                },
                () => {
                    this.onTabClick([
                        {
                            id: 1,
                            name: this.props.t('transaction:transaction.deposit.crypto.crypto', 'Crypto') + ' ' + this.props.t('transaction:transaction.address', 'Address'),
                            bankDetailType: 'crypto',
                            code: 'CT',
                        },
                    ]);
                }
            );
        };

        const onError = (errMsg) => {
            this.setState({ addAddressErrMsg: errMsg });
        };

        APIClient.createMemberCryptoAddress(params, onSuccess, onError);
    };

    handleCryptoAddressDelete(addressID) {
        this.toggleLoading(true);
        window.SPL_Transaction.deleteMemberCryptoAddress(addressID)
            .then(() => {
                this.onTabClick([
                    {
                        id: 1,
                        name: this.props.t('transaction:transaction.deposit.crypto.crypto', 'Crypto') + ' ' + this.props.t('transaction:transaction.address', 'Address'),
                        bankDetailType: 'crypto',
                        code: 'CT',
                    },
                ]);
            })
            .catch((err) => {
                this.toggleLoading(false);
            });
    }

    handleReceiptImageChange(e) {
        wd_handleReceiptImageChange(this, e);
    }

    handleClearReceipt() {
        wd_handleClearReceipt(this);
    }

    getAddressModal(value) {
        this.setState({ addressModal: value });
    }

    onAddCryptoAddressSuccessCb() {
        this.setState({ addressModal: false });
        this.getMemberCryptoAddress();
    }

    renderMemberCryptoBankList() {
        const { t } = this.props;
        return (
            <div className='bankdetail-item-box address-item'>
                {this.state.cryptoAddress && this.state.cryptoAddress.length > 0 && (
                    <Fragment>
                        <div className='address-box-title'>{t('transaction:transaction.deposit.crypto.myCryptoAddress', 'My Crypto Address')}</div>

                        {this.state.cryptoAddress.map((result, index) => {
                            return (
                                <div key={index} className='member-address'>
                                    <div className='address-left'>
                                        <span>
                                            {result.coin} - {result.network}
                                        </span>
                                        <div>{result.address}</div>
                                    </div>
                                    <div className='address-right'>
                                        <div className='delete'>
                                            <div
                                                className={`btn-normal btn-deleteAddress ${this.state.showDelete === result.id ? 'dont-show' : 'show'}`}
                                                onClick={() => this.showDeleteBtn(result.id)}
                                            >
                                                <SVGElements name='delete-icon' />
                                            </div>
                                            <div className={`delete-address ${this.state.showDelete === result.id ? 'show' : 'dont-show'}`}>
                                                <button
                                                    className='btn-normal btn-delete'
                                                    onClick={() => {
                                                        this.handleCryptoAddressDelete(result.id);
                                                    }}
                                                >
                                                    {t('bank:bank.delete', 'Delete')}
                                                </button>
                                                <button className='btn-normal btn-cancel' onClick={() => this.showDeleteBtn('')}>
                                                    {t('bank:bank.cancel', 'Cancel')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Fragment>
                )}
            </div>
        );
    }

    handleSetPreferContact = (contact) => {
        const { selectedContact } = this.state;
        const { authSettingsReducer } = this.props;

        const getAuthMethod = ou_constructContactVerificationMethods(authSettingsReducer?.processedData?.[MT.CRYPTO_ADDRESS]?.authMethod);
        let getContact = contact ? contact : authSettingsReducer?.processedData?.[MT.CRYPTO_ADDRESS]?.authMethod?.[0]?.type;
        const filterAuthMethod = getAuthMethod?.[getContact === 'WHATSAPP' ? 'PHONE' : getContact];

        let repopulatedAuthMethod = getContact === 'PHONE' || getContact === 'WHATSAPP' ? filterAuthMethod.map((item) => ({ displayName: item })) : [];

        const tempSelect = selectedContact.length === 0 ? [repopulatedAuthMethod[0]] : selectedContact;

        this.setState({ preferContact: getContact, repopulatedAuthMethod, selectedContact: tempSelect });
    };

    handlePreferContactChange = (value) => {
        this.setState({ selectedContact: value }, () => {
            const { selectedContact } = this.state;

            const filterSelectedContact = selectedContact[0].displayName;

            this.handleSetPreferContact(filterSelectedContact);
        });
    };

    render() {
        if (this.state.unOwnedBankList !== undefined) {
            const { t } = this.props;
            let bankList = this.state.unOwnedBankList;
            for (let i = 0; i < bankList.length; i++) {
                const bank = this.state.unOwnedBankList[i];
                if (bank.bankName === 'transaction:transaction.withdrawal.otherBanks') {
                    bank.bankName = t('transaction:transaction.withdrawal.otherBanks');
                } else {
                }
            }
        }

        const {
            unOwnedBankList,
            unOwnedEwalletBank,
            bankDetailType,
            addressOption,
            selectedCurrency,
            networkOption,
            selectedNetwork,
            receiptImg,
            receiptImgFile,
            compSettings,
            preferContact,
            attemptCount,
            repopulatedAuthMethod,
            selectedContact,
        } = this.state;
        const { reqBankStatementByCurrencies } = compSettings;
        const { name } = this.props.user.account;
        const { authSettingsReducer, portalSettingsReducer } = this.props;
        const _authSettingsCryptoAddressModule = authSettingsReducer?.processedData?.[MT.CRYPTO_ADDRESS];
        const _authSettingsBankDetailModule = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_BANK];
        const _authSettingsBankDetailEWalletModule = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_EWALLET];

        const _authMethods = ou_filterMasterSwitchAuthMethods(_authSettingsCryptoAddressModule?.authMethod);
        const authMethodType = ou_constructContactVerificationMethods(_authSettingsCryptoAddressModule?.authMethod);

        const chkBankDetailSixPinStatus =
            (_authSettingsBankDetailModule?.sixPinEnabled && _authSettingsBankDetailModule?.memberSixPinInfo?.sixPinLocked) ||
            (_authSettingsBankDetailModule?.sixPinEnabled && !_authSettingsBankDetailModule?.memberSixPinInfo?.sixPinRegistered) ||
            false;

        const chkBankDetailEWalletSixPinStatus =
            (_authSettingsBankDetailEWalletModule?.sixPinEnabled && _authSettingsBankDetailEWalletModule?.memberSixPinInfo?.sixPinLocked) ||
            (_authSettingsBankDetailEWalletModule?.sixPinEnabled && !_authSettingsBankDetailEWalletModule?.memberSixPinInfo?.sixPinRegistered) ||
            false;

        const showBankBranch = gu_filterCommonKey(portalSettingsReducer?.portalSettings?.showBankBranch);

        return (
            <Translation>
                {(t) => (
                    <div className='wap-bankdetails-page'>
                        <AuthSettings module={MT?.CRYPTO_ADDRESS} isPublic={false} />
                        <AuthSettings module={MT?.BANK_DETAIL_BANK} isPublic={false} />
                        <AuthSettings module={MT?.BANK_DETAIL_EWALLET} isPublic={false} />

                        {this.state.addressModal && (
                            <AddCryptoAddressPopup
                                onRequestClose={() => {
                                    this.getAddressModal(false);
                                }}
                                onSubmitSuccessCb={this.onAddCryptoAddressSuccessCb.bind(this)}
                            />
                        )}

                        {this.state.showLoading && <Loading />}

                        <div className='page-content'>
                            <div className='bankdetail-item-box'>
                                <div className='title-item'>
                                    <span>{t('settings:settings.profiletab.bank', 'Bank Details')}</span>
                                </div>

                                <div className='bankdetail-filter'>
                                    {this.bankDetailCategories.length > 0 && (
                                        <Select
                                            key='name'
                                            labelField='name'
                                            valueField='name'
                                            searchable={false}
                                            placeholder={t('global:global.form.pleaseSelect2')}
                                            options={this.bankDetailCategories}
                                            values={this.bankDetailCategories}
                                            onChange={(value) => this.onTabClick(value)}
                                        />
                                    )}
                                </div>
                            </div>

                            {(() => {
                                switch (bankDetailType) {
                                    case 'bank':
                                        return (
                                            <Fragment>
                                                {chkBankDetailSixPinStatus && (
                                                    <Suspense fallback={<div></div>}>
                                                        <SetupSixDigitPinNotice module={MT.BANK_DETAIL_BANK} />
                                                    </Suspense>
                                                )}

                                                {/* Hide the Add Bank Form */}
                                                {!chkBankDetailSixPinStatus && (
                                                    <>
                                                        <div className='bankdetail-item-box'>
                                                            <div className='title-item'>
                                                                <span>
                                                                    {t('bank:bank.bank')} <span className='asterisk'>*</span>
                                                                </span>
                                                            </div>
                                                            <div className='bank-details'>
                                                                <Select
                                                                    noDataRenderer={this.customNoDataRenderer}
                                                                    placeholder={t('bank:bank.newBank.selectABank')}
                                                                    options={unOwnedBankList}
                                                                    labelField='bankName'
                                                                    valueField='bankId'
                                                                    key='bankId'
                                                                    searchable={false}
                                                                    onChange={this.handleBankChange}
                                                                    values={this.state.selectedUnOwned}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={`bankdetail-item-box ${this.state.showBankName ? '' : 'd-none'}`}>
                                                            <div className='title-item'>
                                                                <span>
                                                                    {t('bank:bank.newBank.bankName')} <span className='asterisk'>*</span>
                                                                </span>
                                                            </div>
                                                            <div className='bank-details'>
                                                                <input
                                                                    name='bankName'
                                                                    className={`${this.state.bankNameErrMsg.length > 0 ? 'is-invalid' : ''}`}
                                                                    id='bankName'
                                                                    placeholder={t('bank:bank.newBank.bankName')}
                                                                    value={this.state.bankName}
                                                                    onChange={this.handleBankNameChange}
                                                                />
                                                                {this.state.bankNameErrMsg.map((errMsg, index) => {
                                                                    return (
                                                                        this.state.bankNameErrMsg && (
                                                                            <div key={index} className='invalid-feedback text-danger'>
                                                                                <Trans i18nKey={errMsg}></Trans>
                                                                            </div>
                                                                        )
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                        {showBankBranch && (
                                                            <div className='bankdetail-item-box'>
                                                                <div className='title-item'>
                                                                    <span>{t('bank:bank.branch')}</span>
                                                                </div>
                                                                <div className='bank-details'>
                                                                    <input
                                                                        name='bankbranch'
                                                                        className=''
                                                                        id='bankbranch'
                                                                        type='text'
                                                                        placeholder='Exp : Swan Town'
                                                                        value={this.state.bankBranch}
                                                                        onChange={this.handleBankBranchChange}
                                                                    />
                                                                    {this.state.bankNameErrMsg.map((errMsg, index) => {
                                                                        return (
                                                                            this.state.showBankName && (
                                                                                <div key={index} className='invalid-feedback text-danger'>
                                                                                    <Trans i18nKey={errMsg}></Trans>
                                                                                </div>
                                                                            )
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className='bankdetail-item-box'>
                                                            <div className='title-item'>
                                                                <span>
                                                                    {t('bank:bank.newBank.account-name')} <span className='asterisk'>*</span>
                                                                </span>
                                                            </div>
                                                            <div className='bank-details'>
                                                                <input className='' value={name} readOnly />
                                                                {this.state.bankNameErrMsg.map((errMsg, index) => {
                                                                    return (
                                                                        this.state.showBankName && (
                                                                            <div key={index} className='invalid-feedback text-danger'>
                                                                                <Trans i18nKey={errMsg}></Trans>
                                                                            </div>
                                                                        )
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>

                                                        <div className='bankdetail-item-box'>
                                                            <div className='title-item'>
                                                                <span>
                                                                    {t('bank:bank.newBank.account-number')} <span className='asterisk'>*</span>
                                                                </span>
                                                            </div>
                                                            <div className='bank-details'>
                                                                <input
                                                                    name='accountnumber'
                                                                    id='accountnumber'
                                                                    placeholder='Exp : 1234567'
                                                                    value={this.state.accountNumber}
                                                                    onChange={this.handleAccountNumberChange}
                                                                />

                                                                {this.state.statusMsg.map((errMsg, index) => {
                                                                    return (
                                                                        <div key={index} className='invalid-feedback text-danger'>
                                                                            <Trans i18nKey={errMsg}>{errMsg}</Trans>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>

                                                        {reqBankStatementByCurrencies && (
                                                            <div className='bankdetail-item-box'>
                                                                <div className='title-item'>
                                                                    <span>
                                                                        {t('transaction:transaction.withdrawal.bankStatement', 'Bank Statement')}{' '}
                                                                        <span className='asterisk'>*</span>
                                                                    </span>
                                                                </div>
                                                                <div className='bank-statement-details'>
                                                                    <Button
                                                                        className='standard-button standard-st-upload-button'
                                                                        buttonText={t('transaction:transaction.upload')}
                                                                        onClickFunction={gu_handleUploadReceiptButtonOnClick.bind(this)}
                                                                        handleReceiptFunction={this.handleReceiptImageChange.bind(this)}
                                                                        isReceiptInput={true}
                                                                    />

                                                                    {receiptImg.length > 0 &&
                                                                        (receiptImgFile && receiptImgFile.type === 'application/pdf' ? (
                                                                            <div className='receipt-image-box'>
                                                                                <label>{t('transaction:transaction.deposit.pdf-file-name', 'PDF File Name: ')}</label>
                                                                                <span className='pdf-name'>{receiptImgFile.name}</span>
                                                                                <img
                                                                                    src='/public/images/icon/close-red.png'
                                                                                    className='pdf-clear'
                                                                                    onClick={() => this.handleClearReceipt()}
                                                                                    alt=''
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div className='receipt-image-box'>
                                                                                <img src={receiptImg} alt='receipt.png'></img>
                                                                            </div>
                                                                        ))}

                                                                    <div className='reminder-note-container'>
                                                                        {/* <div className='icon-alert'>
                                                                    <SVG src={`/public/html/images/settings/alert-icon.svg`} />
                                                                </div> */}
                                                                        <i class='icon-tip'></i>
                                                                        <div className='reminder-note'>{t('transaction:transaction:withdrawal.bankStatementRequirement')}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className='bankdetail-item-box'>
                                                            {/* /////////////////////////////// Mobile Add Bank Acc /////////////////////////////////////// */}
                                                            {!this.state.hideAddBankAccount && (
                                                                <div className='bank-addacc'>
                                                                    <button
                                                                        className='btn-normal btn-addbank'
                                                                        onClick={() => this.handleSubmit()}
                                                                        disabled={this.state.disabledButton || (reqBankStatementByCurrencies && receiptImg.length <= 0)}
                                                                    >
                                                                        <span>{t('global:entity.action.save')}</span>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )}

                                                <div className='bankdetail-item-box'>
                                                    {this.state.memberBankList.map((bank) => {
                                                        const { verifiedDisplayStatus } = bank;
                                                        return (
                                                            <div key={bank.memberBankId} className='member-bankcard'>
                                                                <div className='bankcard-left'>
                                                                    {/* <img
                                                                        alt=''
                                                                        src={bank.iconPath ? bank.iconPath : '/public/html/images/bank_img/mobile-otherBank-icon.png'}
                                                                    /> */}
                                                                    <BankIcon bankCode={bank.bankCode || bank.code} isActive={true} />
                                                                    <div>{bank.bankName}</div>
                                                                    {reqBankStatementByCurrencies && (
                                                                        <div className={`bank-status ${verifiedDisplayStatus}`}>
                                                                            {t(`bank:bank.account.verification.${verifiedDisplayStatus}`)}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='bankcard-right'>
                                                                    <div className='bankcard-right-upper'>
                                                                        <div>{bank.accountHolder}</div>
                                                                        <div>{bank.accountNumber}</div>
                                                                        <div>{bank.bankBranch}</div>
                                                                    </div>

                                                                    <div className='delete'>
                                                                        <div
                                                                            className={`btn-normal btn-deletebank ${
                                                                                this.state.showDelete === bank.memberBankId ? 'dont-show' : 'show'
                                                                            }`}
                                                                            onClick={() => this.showDeleteBtn(bank.memberBankId)}
                                                                        >
                                                                            <AiFillDelete className='' /> {t('bank:bank.delete', 'Delete')}
                                                                        </div>
                                                                        <div className={`delete-bank ${this.state.showDelete === bank.memberBankId ? 'show' : 'dont-show'}`}>
                                                                            <button className='btn-normal btn-cancel' onClick={() => this.showDeleteBtn('')}>
                                                                                {t('bank:bank.cancel', 'Cancel')}
                                                                            </button>
                                                                            <button
                                                                                className='btn-normal btn-delete'
                                                                                onClick={() => {
                                                                                    this.handleDelete(bank.memberBankId);
                                                                                }}
                                                                            >
                                                                                {t('bank:bank.delete', 'Delete')}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Fragment>
                                        );
                                        break;

                                    case 'crypto':
                                        if (_authSettingsCryptoAddressModule?.gaEnabled) {
                                            return (
                                                <>
                                                    {this.renderMemberCryptoBankList()}

                                                    <div className='toggle-crypto-popup-button-container'>
                                                        <AddCryptoAddressButton
                                                            module={MT?.CRYPTO_ADDRESS}
                                                            onClick={() => this.getAddressModal(true)}
                                                            text={t('transaction:transaction.deposit.crypto.addAddress', 'Add Crypto Address')}
                                                            className={'standard-button standard-submit-button'}
                                                        />
                                                    </div>
                                                </>
                                            );
                                        }
                                        return (
                                            <Fragment>
                                                <div className='bankdetail-item-box'>
                                                    <div className='title-item'>
                                                        <span>
                                                            {t('transaction:transaction.deposit.crypto.cryptoCurrency', 'Crypto Currency')} <span className='asterisk'>*</span>
                                                        </span>
                                                    </div>
                                                    <div className='bank-details'>
                                                        <Select
                                                            key='cryptoCurrency'
                                                            placeholder={t('transaction:transaction.deposit.crypto.selectCryptoCurrency')}
                                                            options={addressOption}
                                                            labelField='displayName'
                                                            valueField='cryptoCurrency'
                                                            values={selectedCurrency}
                                                            onChange={(values) => this.handleCryptoCurrencyChange(values)}
                                                            searchable={false}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='bankdetail-item-box'>
                                                    <div className='title-item'>
                                                        <span>
                                                            {t('transaction:transaction.deposit.crypto.network', 'Network')} <span className='asterisk'>*</span>
                                                        </span>
                                                    </div>
                                                    <div className='bank-details'>
                                                        <Select
                                                            key='cryptoNetwork'
                                                            placeholder={t('transaction:transaction.deposit.crypto.selectNetwork')}
                                                            options={networkOption}
                                                            labelField='displayName'
                                                            valueField='cryptoNetwork'
                                                            values={selectedNetwork}
                                                            onChange={(values) => this.handleCryptoNetworkChange(values)}
                                                            searchable={false}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='bankdetail-item-box'>
                                                    <div className='title-item'>
                                                        <span>
                                                            {t('transaction:transaction.address', 'Address')} <span className='asterisk'>*</span>
                                                        </span>
                                                    </div>
                                                    <div className='bank-details'>
                                                        <input
                                                            name='accountnumber'
                                                            id='accountnumber'
                                                            placeholder={t('transaction:transaction.address', 'Address')}
                                                            value={this.state.address}
                                                            onChange={this.handleAddressChange}
                                                        />
                                                    </div>
                                                </div>

                                                {!_authSettingsCryptoAddressModule?.gaEnabled && _authSettingsCryptoAddressModule?.otp_feature && _authMethods?.length > 0 && (
                                                    <Suspense fallback={<div>Loading...</div>}>
                                                        <div className='bankdetail-item-box'>
                                                            <AuthOtpTabs
                                                                className=''
                                                                authMethodType={authMethodType?.[preferContact === 'WHATSAPP' ? 'PHONE' : preferContact]}
                                                                handleSetPreferContact={this.handleSetPreferContact}
                                                                preferContact={preferContact}
                                                                useCustomDropdown={true}
                                                            >
                                                                {(_authMethods || []).map((method) => {
                                                                    const { type } = method;
                                                                    const contactType = type && type.toLowerCase();
                                                                    let _preferContact = preferContact === 'WHATSAPP' ? 'PHONE' : preferContact;
                                                                    return (
                                                                        <Fragment key={method.type}>
                                                                            <AuthOtpTab
                                                                                tabText={t(`otp:otp.tab.${contactType}`)}
                                                                                onClick={() => this.handleSetPreferContact(type)}
                                                                                isActive={_preferContact === type}
                                                                            />
                                                                        </Fragment>
                                                                    );
                                                                })}
                                                            </AuthOtpTabs>

                                                            {!CONTACT_VERIFICATION_SINGLE_LAYER.includes(preferContact) && (
                                                                <div className='bankdetail-item-box'>
                                                                    <div className='title-item'>
                                                                        <span>
                                                                            {t('settings:settings.verification.sendTo', 'Send to')}
                                                                            <span className='asterisk'>*</span>
                                                                        </span>
                                                                    </div>
                                                                    <Select
                                                                        key='preferContact'
                                                                        placeholder={t('transaction:transaction.deposit.crypto.selectCryptoCurrency')}
                                                                        options={repopulatedAuthMethod}
                                                                        labelField='displayName'
                                                                        valueField='preferContact'
                                                                        values={selectedContact}
                                                                        onChange={(values) => this.handlePreferContactChange(values)}
                                                                        searchable={false}
                                                                    />
                                                                </div>
                                                            )}

                                                            <div className='title-item'>
                                                                <span>
                                                                    {t('transaction:transaction.otp', 'OTP')}
                                                                    <span className='asterisk'>*</span>
                                                                </span>
                                                            </div>
                                                            <div className='bank-details otp-details'>
                                                                <input
                                                                    id='otp'
                                                                    name='otp'
                                                                    type='number'
                                                                    autoComplete='off'
                                                                    onKeyDown={(evt) => ['e', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                                                                    debounceTimeout={1000}
                                                                    placeholder={t('global:global.verification.enterOTPCode')}
                                                                    value={this.state.otpCode}
                                                                    onChange={this.handleOtpChange}
                                                                    // onBlur={this.validateOtp}
                                                                />
                                                                <button
                                                                    onClick={() => this.sendOtpCode()}
                                                                    disabled={this.state.disableOtpBtn || this.state.countdownTimer > 0}
                                                                >
                                                                    {this.state.submittedOTP === true ? (
                                                                        this.state.countdownTimer > 0 ? (
                                                                            <span>{this.state.countdownTimer}s</span>
                                                                        ) : (
                                                                            <span>{this.props.t('global:global.verification.resend')}</span>
                                                                        )
                                                                    ) : (
                                                                        <span>{t('register:register.form.getCode')}</span>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Suspense>
                                                )}

                                                <div className='bankdetail-item-box'>
                                                    <div className='bank-addacc'>
                                                        {this.state.addAddressErrMsg && (
                                                            <p className={`invalid-feedback text-danger ${this.state.otpErrMsg && 'otp-errMsg'}`}>
                                                                {t(this.state.addAddressErrMsg)}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>

                                                {!this.state.hideAddBankAccount && (
                                                    <div className='bankdetail-item-box'>
                                                        <div className='bank-addacc'>
                                                            <button
                                                                className='btn-normal btn-addbank'
                                                                onClick={() => this.handleCryptoAddressSubmit()}
                                                                disabled={this.state.cryptoAddressDisable}
                                                            >
                                                                <span>{t('global:entity.action.save')}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}

                                                {this.renderMemberCryptoBankList()}
                                            </Fragment>
                                        );
                                        break;

                                    case 'ewallet':
                                        return (
                                            <Fragment>
                                                {chkBankDetailEWalletSixPinStatus && (
                                                    <Suspense fallback={<div></div>}>
                                                        <SetupSixDigitPinNotice module={MT.BANK_DETAIL_EWALLET} />
                                                    </Suspense>
                                                )}
                                                {!chkBankDetailEWalletSixPinStatus && (
                                                    <>
                                                        <div className='bankdetail-item-box'>
                                                            <div className='title-item'>
                                                                <span>
                                                                    {t('bank:bank.newBank.eWallet')} <span className='asterisk'>*</span>
                                                                </span>
                                                            </div>
                                                            <div className='bank-details'>
                                                                <Select
                                                                    noDataRenderer={this.customNoDataRenderer}
                                                                    placeholder={t('bank:bank.newBank.selectABank')}
                                                                    options={unOwnedEwalletBank}
                                                                    labelField='bankName'
                                                                    valueField='bankId'
                                                                    key='bankId'
                                                                    searchable={false}
                                                                    onChange={this.handleBankChange}
                                                                    values={this.state.selectedUnOwned}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={`bankdetail-item-box ${this.state.showBankName ? '' : 'd-none'}`}>
                                                            <div className='title-item'>
                                                                <span>{t('bank:bank.newBank.walletName')}</span>
                                                            </div>
                                                            <div className='bank-details'>
                                                                <input
                                                                    name='bankName'
                                                                    className={`${this.state.bankNameErrMsg.length > 0 ? 'is-invalid' : ''}`}
                                                                    id='bankName'
                                                                    placeholder={t('bank:bank.newBank.bankwalletNameName')}
                                                                    value={this.state.bankName}
                                                                    onChange={this.handleBankNameChange}
                                                                />
                                                                {this.state.bankNameErrMsg.map((errMsg, index) => {
                                                                    return (
                                                                        this.state.bankNameErrMsg && (
                                                                            <div key={index} className='invalid-feedback text-danger'>
                                                                                <Trans i18nKey={errMsg}></Trans>
                                                                            </div>
                                                                        )
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>

                                                        <div className='bankdetail-item-box'>
                                                            <div className='title-item'>
                                                                <span>
                                                                    {t('bank:bank.newBank.walletName')} <span className='asterisk'>*</span>
                                                                </span>
                                                            </div>
                                                            <div className='bank-details'>
                                                                <input className='' value={name} readOnly />
                                                                {this.state.bankNameErrMsg.map((errMsg, index) => {
                                                                    return (
                                                                        this.state.showBankName && (
                                                                            <div key={index} className='invalid-feedback text-danger'>
                                                                                <Trans i18nKey={errMsg}></Trans>
                                                                            </div>
                                                                        )
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>

                                                        <div className='bankdetail-item-box'>
                                                            <div className='title-item'>
                                                                <span>
                                                                    {t('bank:bank.newBank.walletNumber')} <span className='asterisk'>*</span>
                                                                </span>
                                                            </div>
                                                            <div className='bank-details'>
                                                                <input
                                                                    name='accountnumber'
                                                                    id='accountnumber'
                                                                    placeholder='Exp : 1234567'
                                                                    value={this.state.accountNumber}
                                                                    onChange={this.handleAccountNumberChange}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='bankdetail-item-box'>
                                                            {/* /////////////////////////////// Mobile Add Bank Acc /////////////////////////////////////// */}
                                                            <div className='bank-addacc'>
                                                                <button
                                                                    className='btn-normal btn-addbank'
                                                                    onClick={() => this.handleSubmit()}
                                                                    disabled={this.state.disabledButton}
                                                                >
                                                                    <span>{t('global:entity.action.save')}</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className='bankdetail-item-box'>
                                                    {this.state.ewalletBank.map((bank) => {
                                                        return (
                                                            <div key={bank.memberBankId} className='member-bankcard'>
                                                                <div className='bankcard-left'>
                                                                    {/* <img
                                                                        alt=''
                                                                        src={bank.iconPath ? bank.iconPath : '/public/html/images/bank_img/mobile-otherBank-icon.png'}
                                                                    /> */}
                                                                    <BankIcon bankCode={bank.bankCode || bank.code} isActive={true} />
                                                                    <div>{bank.bankName}</div>
                                                                </div>
                                                                <div className='bankcard-right'>
                                                                    <div className='bankcard-right-upper'>
                                                                        {this.props.user && this.props.user.account && this.props.user.account.name && (
                                                                            <div>{this.props.user.account.name}</div>
                                                                        )}
                                                                        <div>{bank.accountNumber}</div>
                                                                    </div>

                                                                    <div className='delete'>
                                                                        <div
                                                                            className={`btn-normal btn-deletebank ${
                                                                                this.state.showDelete === bank.memberBankId ? 'dont-show' : 'show'
                                                                            }`}
                                                                            onClick={() => this.showDeleteBtn(bank.memberBankId)}
                                                                        >
                                                                            <AiFillDelete className='' /> {t('bank:bank.delete', 'Delete')}
                                                                        </div>
                                                                        <div className={`delete-bank ${this.state.showDelete === bank.memberBankId ? 'show' : 'dont-show'}`}>
                                                                            <button className='btn-normal btn-cancel' onClick={() => this.showDeleteBtn('')}>
                                                                                {t('bank:bank.cancel', 'Cancel')}
                                                                            </button>
                                                                            <button
                                                                                className='btn-normal btn-delete'
                                                                                onClick={() => {
                                                                                    this.handleDelete(bank.memberBankId);
                                                                                }}
                                                                            >
                                                                                {t('bank:bank.delete', 'Delete')}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Fragment>
                                        );
                                        break;

                                    default:
                                        break;
                                }
                            })()}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}
export default connect(mapStateToProps)(withTranslation(['transaction', 'bank', 'otp', 'contactus'])(withRouter(BankDetail)));

export const AddCryptoAddressButton = ({ text, module, onClick, disabled, className }) => {
    const { handleSetup } = useAuthSettingsSetup({ module: module, callback: onClick });

    const onAddBtnClick = () => {
        handleSetup();
    };

    return (
        <>
            <div className='standard-button-container'>
                <button className='standard-button standard-submit-button' onClick={onAddBtnClick}>
                    <span>{text}</span>
                </button>
            </div>
        </>
    );
};
